import { Component, OnInit } from '@angular/core';
import {Donor} from "donor-tracking-library";
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private authService: AuthService) { }

  donor: Donor;

  ngOnInit() {
    this.donor = this.authService.userData;
  }

}
