import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AppRoutingModule} from "./app-routing.module";
// import {LoginModule, LayoutModule} from "donor-tracking-library";
import {HttpClientModule} from "@angular/common/http";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "./auth/auth.interceptor";
import {ErrorInterceptor} from "./auth/error-interceptor";
import {LayoutModule} from "./layout/layout.module";
import {LoginComponent} from "./auth/login/login.component";
import {LayoutLoginComponent} from "./layout/layout-login/layout-login.component";
import {MaterialImportsModule} from "./material-imports/material-imports.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BloodTestResultsListComponent } from './blood-test-result/blood-test-results-list/blood-test-results-list.component';
import { MessagingComponent } from './message/messaging/messaging.component';
import {DonorTrackingLibraryModule, MessagingModule} from "donor-tracking-library";
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { ClinicContactsComponent } from './clinic-contacts/clinic-contacts.component';
import { HomeComponent } from './home/home.component';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxMaskModule} from "ngx-mask";
import { EducationComponent } from './education/education.component';
import {apiURL, wsURL} from './settings';
import {AuthService} from './auth/auth.service';
import {PushNotificationsService} from './push-notifications/push-notifications.service';
// import {AgmCoreModule} from "@agm/core";

export class DonorHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    pan: {direction: Hammer.DIRECTION_ALL},
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutLoginComponent,
    BloodTestResultsListComponent,
    MessagingComponent,
    ForgotPasswordComponent,
    ProfileEditComponent,
    ClinicContactsComponent,
    HomeComponent,
    EducationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialImportsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DonorTrackingLibraryModule,
    NgxMaskModule.forRoot(),
    MessagingModule.forRoot({
      messages_url: `${apiURL}/message/`,
      messages_page_size: 40,
      auth: AuthService,
      socket_url: `${wsURL}/messages/`,
      theme_color: '#6b4c87',
      full_screen_mode: true,
      show_sender_name: false,
      push: PushNotificationsService
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAO69h74RcCAyC6oPVuQ1IkHNsHa_ydPBk'
    // })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: DonorHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
