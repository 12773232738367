import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import {Donor, MessagingService} from 'donor-tracking-library';
import {LABCORP_ON_GOOGLE_MAPS_URL} from '../../settings';
import {ProfileService} from '../../profile/profile.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') $drawer;
  sidenavMode = 'side';
  resizeSubscription: Subscription;
  resize$ = fromEvent(window, 'resize').pipe(debounceTime(500));

  user$: Observable<Donor>;

  refreshChildContentMethod: () => Observable<any> = null;
  childComponent: any;

  constructor(private authService: AuthService, public router: Router, private messageService: MessagingService,
              private profileService: ProfileService) {}

  ngOnInit() {
    this.resizeSubscription = this.resize$.subscribe(() => this.resizeHandler());
    this.resizeHandler();
    if (this.sidenavMode === 'side') {
      this.toggleSidenav();
    }

    this.user$ = this.profileService.profileData;
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  setChildComponent(componentRef) {
    this.childComponent = componentRef;
    this.refreshChildContentMethod = ((componentRef.refresh) ? componentRef.refresh : null);
  }

  refresh() {
    if (this.refreshChildContentMethod) {
      this.refreshChildContentMethod.bind(this.childComponent)();
    }
  }

  toggleSidenav() {
    this.$drawer.toggle();
  }

  resizeHandler() {
    const width = window.innerWidth;
    this.sidenavMode = width > 1100 ? 'side' : 'over';
  }

  onClickLogout() {
    // TODO: Move to doUnauthorizedStuff method in auth service after solving circular dependency
    this.messageService.closeLiveChat();
    this.authService.logout();
  }

  goToMaps() {
    window.location.href = LABCORP_ON_GOOGLE_MAPS_URL;
  }
}
