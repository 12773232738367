import { Component, OnInit } from '@angular/core';
import {ClinicService} from "./clinic.service";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-clinic-contacts',
  templateUrl: './clinic-contacts.component.html',
  styleUrls: ['./clinic-contacts.component.scss']
})
export class ClinicContactsComponent implements OnInit {

  constructor(private clinicService: ClinicService) { }

  clinic$;
  isLoading: boolean;

  ngOnInit() {
    this.isLoading = true;
    this.refresh();
  }

  refresh() {
    this.clinic$ = this.clinicService.clinicDetails()
      .pipe(tap(() => this.isLoading = false));
  }
}
