import {Injectable, Injector} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';
import {tap} from 'rxjs/operators';
import {MessagingService} from 'donor-tracking-library';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  auth: AuthService;
  constructor(private injector: Injector, private messageService: MessagingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.auth = this.injector.get(AuthService);
    return next.handle(req)
      .pipe(tap(event => {
        return event;
      },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              // TODO: Move to doUnauthorizedStuff method in auth service after solving circular dependency
              this.messageService.closeLiveChat();
              this.auth.doUnauthorizedStuff();
            }
          }
          return err;
      }));
  }
}
