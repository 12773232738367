import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutLoginComponent} from "./layout/layout-login/layout-login.component";
import {LoginComponent} from "./auth/login/login.component";
import {AuthenticatedGuard} from "./auth/authenticated.guard";
import {AuthGuard} from "./auth/auth.guard";
import {LayoutComponent} from "./layout/layout/layout.component";
import {BloodTestResultsListComponent} from "./blood-test-result/blood-test-results-list/blood-test-results-list.component";
import {MessagingComponent} from "./message/messaging/messaging.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {ProfileEditComponent} from "./profile/profile-edit/profile-edit.component";
import {ClinicContactsComponent} from "./clinic-contacts/clinic-contacts.component";
import {HomeComponent} from "./home/home.component";
import {EducationComponent} from "./education/education.component";
// import {LoginComponent, LayoutComponent, LayoutLoginComponent} from "donor-tracking-library";
// import {AuthService} from "./auth/auth.service";



const routes: Routes = [
  // {
  //   path: '',
  //   component: LayoutLoginComponent,
  //   data : {
  //     authServiceClass : AuthService,
  //   },
  //   children: [
  //     {
  //       path: 'login',
  //       component: LoginComponent,
  //       data : {
  //         authServiceClass : AuthService,
  //         homePagePath: '/'
  //       }
  //     },
  //   ],
  // },
  {
    path: '',
    component: LayoutLoginComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [AuthenticatedGuard]
      }
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'blood-test-results',
        component: BloodTestResultsListComponent
      },
      {
        path: 'profile-edit',
        component: ProfileEditComponent
      },
      {
        path: 'clinic-chat',
        component: MessagingComponent
      },
      {
        path: 'clinic-contacts',
        component: ClinicContactsComponent
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'education',
        component: EducationComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
