import {Component, OnInit, ViewChild} from '@angular/core';
import { PreviewMessage, MessagingService } from 'donor-tracking-library';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {

  @ViewChild('chatView') chatViewElement: any;

  messageList: PreviewMessage[] = [];

  clinic_name: string;
  user_id: number;

  isLoading;
  isReconnecting$;

  replyContent = '';
  constructor(private messageService: MessagingService, private authService: AuthService,
              private router: Router) { }

  ngOnInit() {
    this.isLoading = true;

    this.clinic_name = this.authService.userData.clinic_details.name;
    this.user_id = this.authService.userData.user_id;
    this.isReconnecting$ = this.messageService.isWebSocketReconnecting;
  }
  refresh() {
    // TODO: fix: not calling ngOnInit on mobile!
    this.router.navigateByUrl(this.router.url);
  }
}
