import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiURL} from "../settings";

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

  template$;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.template$ = this.http.get(`${apiURL}/education/`, {responseType: 'text'});
  }

}
