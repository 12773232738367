import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder} from "@angular/forms";
import {passwordMinLength} from "../../settings";
import {ORGAN_CHOICES, GENDER_CHOICES} from "donor-tracking-library";
import * as moment from 'moment';
import {MatSnackBar} from "@angular/material";
import { Location } from '@angular/common';
import {ProfileService} from "../profile.service";


@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  updateLoading: boolean;
  updateError: boolean;
  updateErrorObject: any;
  profileCurrentInfo: any;

  showPass: boolean = false;
  passFieldType: string = 'password';

  profileForm;

  ORGAN_CHOICES = ORGAN_CHOICES;

  GENDER_CHOICES = GENDER_CHOICES;

  ngOnInit() {
    this.profileService.profileData.subscribe((donor) => {
      this.profileCurrentInfo = {
        first_name: donor.first_name,
        last_name: donor.last_name,
        user: {
          email: donor.email,
          password: ''
        },
        birth_date: donor.birth_date,
        gender: donor.gender,
        phone: donor.phone,
        address: donor.address,
        organ: donor.organ
      };

      this.profileForm = this.fb.group({
        first_name: [this.profileCurrentInfo.first_name, [Validators.required]],
        last_name: [this.profileCurrentInfo.last_name, [Validators.required]],
        user: this.fb.group({
          email: [this.profileCurrentInfo.user.email, [Validators.email, Validators.required]],
          password: ['', [Validators.minLength(passwordMinLength)]],
        }),
        birth_date: [this.profileCurrentInfo.birth_date, [Validators.required]],
        gender: [this.profileCurrentInfo.gender, [Validators.required]],
        phone: [this.profileCurrentInfo.phone, [Validators.pattern('[0-9]{10}')]],
        address: [this.profileCurrentInfo.address, []],
        organ: [this.profileCurrentInfo.organ, [Validators.required]]
      });
    });
  };


  constructor(private profileService: ProfileService,
              private snackBar: MatSnackBar,
              private location: Location,
              private fb: FormBuilder) {
  }

  update(){
    this.updateError = false;
    this.updateErrorObject = null;
    this.updateLoading = true;
    const formValue = this.profileForm.value;
    if(!formValue.user.password)
      delete formValue.user.password;
    const donorData = Object.assign(
      {},
      formValue
      , {
        birth_date: moment(formValue.birth_date).format('YYYY-MM-DD'),
      }
    );

    this.profileService.update(donorData)
      .subscribe(
        (res) => {
          this.updateLoading = false;
          this.openSnackBar('Profile updated successfully!', 'OK')
        },
        (errorRes) => {
          this.updateLoading = false;
          this.updateError = true;
          this.updateErrorObject = errorRes.error;
        }
      )
  }

  cancel(){
    this.location.back();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    }).afterDismissed().subscribe();
  }

  isInfoNotChanged() {
    return JSON.stringify(this.profileForm.value) === JSON.stringify(this.profileCurrentInfo)
  }

  toggleShowPassword() {
    this.showPass = !this.showPass;
    this.passFieldType = this.showPass ? 'text' : 'password';
  }
}
