import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiURL} from "../settings";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BloodTestResultService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    const testResultsList = `${apiURL}/test-results/blood/`;
    return this.http.get(testResultsList);
  }
}
