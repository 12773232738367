import { Component } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {passwordMinLength} from '../../settings';
import {Router} from '@angular/router';
import {PushNotificationsService} from '../../push-notifications/push-notifications.service';
import { donorMobileAppVersion } from '../../../../../../package.json';
import {MessagingService} from 'donor-tracking-library';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  appVersion: string = donorMobileAppVersion;

  loginLoading: boolean;
  loginError: boolean;
  errorMessage: string;

  showPass = false;
  passFieldType = 'password';


  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router,
              private pushService: PushNotificationsService,
              private messageService: MessagingService) { }

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(passwordMinLength)]],
  });


  getErrorMessage(controllerName: string) {
    const { errors } = this.loginForm.controls[controllerName];
    return errors.required
      ? `${controllerName} is required`
      : `Please enter a valid ${controllerName}`;
  }

  login() {
    this.loginLoading = true;
    this.loginError = false;
    this.errorMessage = null;
    return this.auth.login(this.loginForm.value).subscribe({
      next: (res) => {
        this.router.navigate(['/home']);
        this.loginLoading = false;
        try {
          this.pushService.initialize();
        } catch (e) {
          // TODO: Do some stuff if it's a mobile!
          console.log(e);
        }
        this.messageService.startLiveChat();
      },
      error: (errorRes) => {
        this.loginError = true;
        this.loginLoading = false;
        this.errorMessage = errorRes.error;
      },
    });
  }
  goToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  toggleShowPassword() {
    this.showPass = !this.showPass;
    this.passFieldType = this.showPass ? 'text' : 'password';
  }

}
