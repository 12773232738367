import {
  Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { ILink, sidenavLinks } from './sidenav-links';
import { MessagingService } from 'donor-tracking-library';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  @Input() type = 'side';
  @Input() user: any;
  @Input() user_role: string;
  @Input() activeRoute: string | string[];
  @Output() logout = new EventEmitter();
  @Output() toggle = new EventEmitter();
  routes: ILink[] = sidenavLinks;

  constructor(private messageService: MessagingService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  unreadMessagesCount: number;

  ngOnInit() {
    this.messageService.totalUnReadMsgsCount.subscribe((count) => {
      this.unreadMessagesCount = count;
      this.changeDetectorRef.detectChanges();
    });
  }

  onClickLogout() {
    this.logout.emit();
  }

  onRouting(disable_case) {
    if(this.isLinkEnabled(disable_case))
      this.toggle.emit()
  }

  isRouteActive(url) {
    const isString = typeof url === 'string';
    return (isString)
        ? url === this.activeRoute
        : url.join('') === this.activeRoute;
  }

  isLinkEnabled(disable_case) {
    if(disable_case == 'nurse_not_assigned')
      return !!this.user.nurse;
    return true;
  }
}
