import { Component, OnInit } from '@angular/core';
import {BloodTestResultService} from "../blood-test-result.service";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";


@Component({
  selector: 'app-blood-test-results-list',
  templateUrl: './blood-test-results-list.component.html',
  styleUrls: ['./blood-test-results-list.component.scss'],
})
export class BloodTestResultsListComponent implements OnInit{

  blood_test_results$: Observable<any>;
  isLoading: boolean = false;

  constructor(private testResultsService: BloodTestResultService) { }

  ngOnInit() {
    this.blood_test_results$ = this.testResultsService.list();
  }

  refresh(event) {
    this.isLoading = true;
    this.blood_test_results$ = this.testResultsService.list()
      .pipe(tap(() => this.isLoading = false));
  }

}
