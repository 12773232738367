import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {
  CanActivate,
  Router,
} from '@angular/router';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.loggedInBefore().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.authService.doUnauthorizedStuff();
          this.router.navigate(['/login']);
        }
        return isLoggedIn;
      })
    );
  }
}
