import {Injectable} from '@angular/core';

import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {DonorAuthResponse, Donor} from "donor-tracking-library";
import {apiURL, authTokenStoreName} from "../settings";
import {map, catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {ProfileService} from "../profile/profile.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private profileServie: ProfileService) {
  }

  userData: Donor;
  userRole;

  login(credentials): Observable<any> {
    const loginURL = `${apiURL}/auth/login/`;

    return this.http.post(loginURL, {
      email: credentials.email,
      password: credentials.password
    }).pipe(map((resp: DonorAuthResponse) => {
      this.setToken(resp.token);
      let profile = resp.user_data as Donor;
      this.profileServie.setProfile(profile);
      //TODO: remove userData, just use profileService.profileData everywhere
      this.userData = resp.user_data as Donor;
      return resp;
    }));
  }

  private setToken(token: string) {
    localStorage.setItem(authTokenStoreName, token);
  }

  forgotPassword(email: string) {
    const forgotPasswordURL = `${apiURL}/auth/forgot-password/`;

    return this.http.post(forgotPasswordURL, {email});
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token && token.length != 0;
  }

  getToken(): string {
    return localStorage.getItem(authTokenStoreName);
  }

  logout() {
    const logoutUrl = `${apiURL}/auth/logout/`;

    if (this.loggedInBefore()) {
      this.http.post(logoutUrl, {}).subscribe({
        next: (resp) => {
          this.doUnauthorizedStuff();
        },
        error: (err) => console.log
      });
    }
    else
      this.doUnauthorizedStuff()
  }

  loggedInBefore(): Observable<any> {
    const userObservable = this.profile();

    return userObservable.pipe(
      map((userDetails) => {
        let profile = userDetails as Donor;
        this.profileServie.setProfile(profile);
        //TODO: remove userData, just use profileService.profileData everywhere
        this.userData = userDetails as Donor;
        return !!userDetails
      }),
      catchError((err) => of(false))
    );
  }

  doUnauthorizedStuff() {
    localStorage.removeItem(authTokenStoreName);
    this.router.navigateByUrl(`/login`);
  }

  // TODO: move profile to a proper service after other apis are to be implemented
  profile() {
    const profileUrl = `${apiURL}/donor/profile/`;
    return this.http.get(profileUrl)
  }

}
