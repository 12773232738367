export interface ILink {
  link: string | string[];
  icon?: string;
  text: string;
  disable_case: string;
  disable_tooltip_msg: string;
  childs?: ILink[];
}


export const sidenavLinks: ILink[] = [
  {
    link: ['/home'],
    text: 'Home',
    icon: 'home',
    disable_case: null,
    disable_tooltip_msg: null
  },
  {
    link: ['/blood-test-results'],
    text: 'Lab results',
    icon: 'local_hospital',
    disable_case: null,
    disable_tooltip_msg: null
  },
  {
    link: ['/clinic-chat'],
    text: 'Message the clinic',
    icon: 'chat',
    disable_case: 'nurse_not_assigned',
    disable_tooltip_msg: "You must be assigned to a nurse first"
  },
  {
    link: ['/clinic-contacts'],
    text: 'Clinic info',
    icon: 'contacts',
    disable_case: null,
    disable_tooltip_msg: null
  },
  {
    link: ['/profile-edit'],
    text: 'Account Details',
    icon: 'account_box',
    disable_case: null,
    disable_tooltip_msg: null
  },
  {
    link: ['/education'],
    text: 'Education',
    icon: 'school',
    disable_case: null,
    disable_tooltip_msg: null
  },
];
