import { Injectable } from '@angular/core';
import {Platform} from "@angular/cdk/platform";
import {HttpClient} from "@angular/common/http";
import {apiURL} from "../settings";
import {ProfileService} from "../profile/profile.service";
declare var PushNotification:any;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private http: HttpClient, private platform: Platform, private profileService: ProfileService) {
  }

  ANDROID_LABEL = "android";
  IOS_LABEL = "ios";

  messageHandler;

  push;

  initialize() {
    try {
      this.push = PushNotification.init({
        android: {
        },
        browser: {
          pushServiceURL: 'http://push.api.phonegap.com/v1/push'
        },
        ios: {
          alert: "true",
          badge: "true",
          sound: "true"
        },
        windows: {}
      });

      this.push.on('registration', (registrationData) => {
        console.log('Device registered', registrationData);
        let deviceType;

        if(this.platform.IOS) {
          deviceType = this.IOS_LABEL;
        }
        else if(this.platform.ANDROID) {
          deviceType = this.ANDROID_LABEL;
        }
        return this.http.post(`${apiURL}/device-registrationID/`, {device_type: deviceType, registration_id: registrationData.registrationId}).toPromise();
      });

      this.push.on('notification', (data) => {
        console.log(data);
        if(data.title === 'nurse_assigned')
          this.profileService.refreshProfile()
            .subscribe();
      });

      this.push.on('error', (e) => {
        console.log('push error', e);
      });
    } catch (e) {
      console.log(e);
    }
  }

  setNotificationCallback(callBack) {
    if (!this.push) {
      this.initialize();
    }
    if (this.messageHandler) {
      this.push.off('notification', this.messageHandler);
    }
    this.messageHandler = callBack;
    try {
      this.push.on('notification', callBack);
    } catch (e) {
      console.log(e);
    }
  }

  removeMessageListener() {
    this.removeNotificationCallback(this.messageHandler);
    this.messageHandler = null;
  }
  removeNotificationCallback(callBack) {
    if(this.push)
      this.push.off('notification', callBack);
  }
}
