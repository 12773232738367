import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiURL} from "../settings";
import {map} from "rxjs/operators";
import {Donor} from "donor-tracking-library";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  profileUrl: string = `${apiURL}/donor/profile/`;

  constructor(private http: HttpClient) { }

  private profileDataSource = new BehaviorSubject<any>(null);
  public profileData = this.profileDataSource.asObservable();

  refreshProfile() {
    const profileUrl = `${apiURL}/donor/profile/`;
    return this.http.get(profileUrl).pipe(map((profileData => {
        this.profileDataSource.next(profileData)
      }
    )))
  }

  setProfile(profileData) {
    this.profileDataSource.next(profileData)
  }

  update(updated_data) {
    return this.http.patch(this.profileUrl, updated_data)
    .pipe(map((donorData: Donor) => {
      this.profileDataSource.next(donorData);
      return donorData;
    }))
  }
}
